<template>
  <div>
    <!-- Add Admin -->
    <b-card-group deck>
      <b-card
        header-tag="header"
        title="إضافة كلاس جديد"
      >
        <validation-observer ref="adminRules">
          <b-form>
            <b-row>
              <!-- name -->
              <b-col md="4">
                <b-form-group
                  label="الاسم"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="الاسم"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="تاريخ البدء"
                  label-for="birthday"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="birthday"
                    rules="required"
                  >
                    <b-form-input
                      id="birthday"
                      v-model="startDate"
                      type="date"
                      :state="errors.length > 0 ? false : null"
                      placeholder="تاريخ الميلاد"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="تاريخ الانتهاء"
                  label-for="birthday"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="birthday"
                    rules="required"
                  >
                    <b-form-input
                      id="birthday"
                      v-model="endDate"
                      type="date"
                      :state="errors.length > 0 ? false : null"
                      placeholder="تاريخ الميلاد"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <hr class="w-100 mx-2">
              <b-col md="6">
                <b-form-group
                  label="الايام المتاحه"
                  label-for="birthday"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="birthday"
                    rules="required"
                  >
                    <b-form-checkbox-group
                      v-model="selected"
                      :options="options"
                      class="mb-3 mt-2"
                      value-field="item"
                      text-field="name"
                      disabled-field="notEnabled"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="عدد الافراد"
                  label-for="limit"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="birthday"
                    rules="required"
                  >
                    <b-form-input
                      id="birthday"
                      v-model="limit"
                      class="mt-1"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="عدد الافراد"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="الوصف"
                  label-for="description"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="description"
                    rules="required"
                  >
                    <b-form-textarea
                      id="textarea"
                      v-model="description"
                      placeholder="الوصف"
                      rows="3"
                      max-rows="6"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- create button -->
              <b-col cols="12">
                <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  إضافة
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@/@core/utils/validations/validations'
import {
  BCard,
  BCardGroup,
  BButton,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormTextarea,
  BFormCheckboxGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const dictionary = {
  ar: {
    names: {
      name: 'الاسم',
      email: 'البريد الالكتروني',
      password: 'كلمة المرور',
      description: 'الوصف',
      role: 'النوع',
    },
  },
}
localize('ar', dictionary.ar)
export default {
  name: 'AddCategory',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardGroup,
    BButton,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormCheckboxGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BForm,
    BRow,
    BCol,
  },
  data() {
    return {
      description: '',
      name: '',
      startDate: '',
      endDate: '',
      limit: '',
      days: [],
      selected: [],
      options: [
        { item: '6', name: 'السبت' },
        { item: '0', name: 'الاحد' },
        { item: '1', name: 'الاثنين' },
        { item: '2', name: 'الثلاثاء' },
        { item: '3', name: 'الاربعاء' },
        { item: '4', name: 'الخميس' },
        { item: '5', name: 'الجمعة' },
      ],
      required,
    }
  },
  // computed: {
  //   rows() {
  //     // return this.getCategories.length
  //     return this.items.length
  //   },
  //   getCategories() {
  //     return this.$store.state.categories.categories
  //   // },
  // },
  created() {
    this.$store.dispatch('roles/fetch').then(cats => {
      this.roles = cats.data.data
    })
  },
  methods: {
    validationForm() {
      const formData = new FormData()
      formData.append('name', this.name)
      formData.append('description', this.description)
      formData.append('started_at', this.startDate)
      formData.append('ended_at', this.endDate)
      formData.append('days', this.selected)
      formData.append('limit', this.limit)
      console.log(formData)
      this.$refs.adminRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('room/store', formData).then(() => {
            this.$router.push({ name: 'rooms' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'تم إضافة الكلاس بنجاح',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
        }
      })
    },
    fileCategory(event) {
      // eslint-disable-next-line prefer-destructuring
      this.category_file = event.target.files[0]
    },
  },
}
</script>

<style scoped></style>
