<template>
  <div>
    <!-- Add Admin -->
    <b-card-group deck>
      <b-card
        header-tag="header"
        title="إضافة عضو جديد"
      >
        <validation-observer ref="adminRules">
          <b-form>
            <b-row>
              <!-- name -->
              <b-col md="6">
                <b-form-group
                  label="الاسم"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="الاسم"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="رقم الهاتف"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="phone"
                    rules="required"
                  >
                    <b-form-input
                      id="phone"
                      v-model="phone"
                      :state="errors.length > 0 ? false : null"
                      placeholder="رقم الهاتف"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="رقم هاتف الطوارئ"
                  label-for="emergency_phone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="emergency_phone"
                    rules="required"
                  >
                    <b-form-input
                      id="emergency_phone"
                      v-model="emergency_phone"
                      :state="errors.length > 0 ? false : null"
                      placeholder="رقم الهاتف"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="البريد الالكتروني"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required"
                  >
                    <b-form-input
                      id="email"
                      v-model="email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="البريد الالكتروني"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="تاريخ الميلاد"
                  label-for="birthday"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="birthday"
                    rules="required"
                  >
                    <b-form-input
                      id="birthday"
                      v-model="birthday"
                      type="date"
                      :state="errors.length > 0 ? false : null"
                      placeholder="تاريخ الميلاد"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- create button -->
              <b-col cols="12">
                <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  إضافة
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@/@core/utils/validations/validations'
import {
  BCard,
  BCardGroup,
  BButton,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const dictionary = {
  ar: {
    names: {
      name: 'الاسم',
      email: 'البريد الالكتروني',
      password: 'كلمة المرور',
      phone: 'رقم الهاتف',
      role: 'النوع',
    },
  },
}
localize('ar', dictionary.ar)
export default {
  name: 'AddCategory',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardGroup,
    BButton,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
  },
  data() {
    return {
      name: null,
      email: '',
      password: '',
      birthday: '',
      phone: '',
      emergency_phone: '',
      role: '',
      roles: {},
      required,
    }
  },
  // computed: {
  //   rows() {
  //     // return this.getCategories.length
  //     return this.items.length
  //   },
  //   getCategories() {
  //     return this.$store.state.categories.categories
  //   // },
  // },
  created() {
    this.$store.dispatch('roles/fetch').then(cats => {
      this.roles = cats.data.data
    })
  },
  methods: {
    validationForm() {
      const formData = new FormData()
      formData.append('name', this.name)
      formData.append('email', this.email)
      formData.append('phone', this.phone)
      formData.append('emergency_phone', this.emergency_phone)
      formData.append('birthday', this.birthday)
      console.log(formData)
      this.$refs.adminRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('member/store', formData).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'تم إضافة عضو بنجاح',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
        }
      })
    },
    fileCategory(event) {
      // eslint-disable-next-line prefer-destructuring
      this.category_file = event.target.files[0]
    },
  },
}
</script>

<style scoped></style>
