import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import categories from './categories'
import common from './common'
import users from './users'
import admins from './admins'
import member from './member'
import memberShip from './member-ship'
import setting from './setting'
import roles from './roles'
import subscribe from './subscribe'
import room from './room'
import coach from './coach'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    categories,
    common,
    users,
    admins,
    member,
    memberShip,
    setting,
    roles,
    subscribe,
    room,
    coach,
  },
  strict: process.env.DEV,
})
