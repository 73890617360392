<template>
  <b-modal
    id="EditCat"
    cancel-variant="outline-secondary"
    ok-title="تحديث"
    cancel-title="الغاء"
    centered
    size="sm"
    title="تعديل المسئول"
    @ok="update"
  >
    <validation-observer ref="adminRules">
      <b-form>
        <b-row>
          <!-- name -->
          <b-col md="12">
            <b-form-group
              label="الاسم"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="dataName"
                  :state="errors.length > 0 ? false : null"
                  placeholder="الاسم"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="رقم الهاتف"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="phone"
                rules="required"
              >
                <b-form-input
                  id="phone"
                  v-model="dataPhone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="رقم الهاتف"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="البريد الالكتروني"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="dataEmail"
                  :state="errors.length > 0 ? false : null"
                  placeholder="البريد الالكتروني"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="النوع"
              label-for="role"
            >
              <validation-provider
                #default="{ errors }"
                name="role"
                rules="required"
              >
                <b-form-select
                  id="role"
                  v-model="dateRole"
                  value-field="id"
                  text-field="name"
                  placeholder="النوع"
                  :options="roles"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@/@core/utils/validations/validations'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BModal,
  BFormSelect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const dictionary = {
  ar: {
    names: {
      name: 'اسم القسم',
      type: 'نوع القسم',
      image: 'صورة القسم',
      color: 'اللون',
    },
  },
}
localize('ar', dictionary.ar)
export default {
  name: 'EditCategory',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BModal,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    obj: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  data() {
    return {
      dataId: '',
      dataName: '',
      dataEmail: '',
      dataPhone: '',
      dateRole: '',
      roles: [],
      required,
    }
  },
  watch: {
    obj() {
      const {
        id, name, email, phone, role_id,
      } = JSON.parse(
        JSON.stringify(this.obj),
      )
      this.dataId = id
      this.dataName = name
      this.dataEmail = email
      this.dataPhone = phone
      // eslint-disable-next-line camelcase
      this.dateRole = role_id
    },
  },
  mounted() {
    console.clear()
  },
  created() {
    this.$store.dispatch('roles/fetch').then(cats => {
      this.roles = cats.data.data
    })
  },
  methods: {
    update(e) {
      e.preventDefault()
      this.$refs.adminRules.validate().then(success => {
        if (success) {
          const Obj = new FormData()
          Obj.append('id', this.dataId)
          Obj.append('admin_id', this.dataId)
          Obj.append('name', this.dataName)
          Obj.append('email', this.dataEmail)
          Obj.append('phone', this.dataPhone)
          Obj.append('role_id', this.dateRole)
          if (typeof this.dataImage === 'object') {
            Obj.append('image', this.dataImage)
          }
          this.$store.dispatch('admins/update', Obj).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'تم تعديل المسئول بنجاح',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
          this.$bvModal.hide('EditCat')
        } else {
          e.preventDefault()
        }
      })
    },
    fileCategory(event) {
      // eslint-disable-next-line prefer-destructuring
      this.dataImage = event.target.files[0]
    },
  },
}
</script>

<style scoped></style>
