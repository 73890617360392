export default [
  // {
  //   header: 'Apps & Pages',
  // },
  // {
  //   title: 'الصفحة الرئيسية',
  //   route: 'home',
  //   icon: 'HomeIcon',
  //   role: 'admin',
  // },
  // {
  //   title: 'المسئولين',
  //   icon: 'FolderMinusIcon',
  //   role: 'admin',
  //   children: [
  //     {
  //       title: 'إضافة مسئول',
  //       route: 'add-category',
  //     },
  //     {
  //       title: 'كافة المسئولين',
  //       route: 'all-category',
  //     },
  //   ],
  // },
  // {
  //   title: 'الاعضاء',
  //   route: 'second-page',
  //   icon: 'FolderMinusIcon',
  //   role: 'admin',
  //   children: [
  //     {
  //       title: 'إضافة عضو',
  //       route: 'add-members',
  //     },
  //     {
  //       title: 'كل الاعضاء',
  //       route: 'members',
  //     },
  //   ],
  // },
  // {
  //   title: 'المدربين',
  //   route: 'second-page',
  //   icon: 'FolderMinusIcon',
  //   role: 'admin',
  //   children: [
  //     {
  //       title: 'إضافة مدرب',
  //       route: 'add-coaches',
  //     },
  //     {
  //       title: 'كل المدربيين',
  //       route: 'coaches',
  //     },
  //   ],
  // },
  {
    title: 'الكلاسات',
    route: 'second-page',
    icon: 'FolderMinusIcon',
    role: 'admin',
    children: [
      // {
      //   title: 'إضافة كلاس',
      //   route: 'add-rooms',
      // },
      {
        title: 'الكلاسات اليومية',
        route: 'rooms',
      },
    ],
  },
  // {
  //   title: 'الباقات',
  //   route: 'second-page',
  //   icon: 'FolderMinusIcon',
  //   role: 'admin',
  //   children: [
  //     {
  //       title: 'تعيين العضو',
  //       route: '',
  //     },
  //     {
  //       title: 'إضافة باقة',
  //       route: 'add-packages',
  //     },
  //     {
  //       title: 'كل الباقات',
  //       route: 'packages',
  //     },
  //   ],
  // },
  // {
  //   title: 'الاشتركات',
  //   icon: 'FolderMinusIcon',
  //   role: 'admin',
  //   children: [
  //     {
  //       title: 'إضافة اشتراك',
  //       route: 'add-subscribe',
  //     },
  //     {
  //       title: 'كل الاشتراكات',
  //       route: 'subscribes',
  //     },
  //     {
  //       title: 'كل الاشتراكات المفعلة',
  //       route: 'subscribes-active',
  //     },
  //     {
  //       title: 'كل الاشتراكات المنتهية',
  //       route: 'subscribes-expired',
  //     },
  //   ],
  // },
  // {
  //   title: 'الكلاسات',
  //   route: 'reports.all',
  //   icon: 'FolderMinusIcon',
  //   role: 'admin',
  // },
  // {
  //   title: 'التقاريير',
  //   route: 'reports.all',
  //   icon: 'HelpCircleIcon',
  //   role: 'admin',
  // },
  // {
  //   title: 'الإعدادات',
  //   icon: 'SettingsIcon',
  //   route: 'setting.all',
  //   role: 'admin',
  // },
  {
    title: 'تسجيل الخروج',
    route: 'login',
    icon: 'LogOutIcon',
    role: 'admin',
  },
]
