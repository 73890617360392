import { render, staticRenderFns } from "./EditSetting.vue?vue&type=template&id=01371dd1&scoped=true&"
import script from "./EditSetting.vue?vue&type=script&lang=js&"
export * from "./EditSetting.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01371dd1",
  null
  
)

export default component.exports