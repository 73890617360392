import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '@/middleware/index'

Vue.use(VueRouter)

/**
 *
 * @type {VueRouter}
 */
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    /**
     * Admin section
     */
    {
      path: '/',
      name: 'home',
      role: 'admin',
      component: () => import('@/views/admin/rooms/AllRooms'),
      meta: {
        authRequired: true,
        pageTitle: 'الصحفة الرئيسية',
        breadcrumb: [
          {
            text: 'الصفحة الرئيسية',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-admins',
      name: 'add-category',
      component: () => import('@/views/admin/admins/AddCategory'),
      meta: {
        authRequired: true,
        role: 'admin',
        pageTitle: 'إضافة التصنيفات',
        breadcrumb: [
          {
            text: 'إضافة التصنيفات',
            active: true,
          },
        ],
      },
    },
    {
      path: '/all-admins',
      name: 'all-category',
      component: () => import('@/views/admin/admins/AllCategory'),
      meta: {
        authRequired: true,
        role: 'admin',
        pageTitle: 'التصنيفات',
        breadcrumb: [
          {
            text: 'التصنيفات',
            active: true,
          },
        ],
      },
    },
    {
      path: '/all-sub-admins/:id',
      name: 'all-sub-admins',
      component: () => import('@/views/admin/admins/AllSubCategory'),
      meta: {
        authRequired: true,
        role: 'admin',
        pageTitle: 'التصنيفات',
        breadcrumb: [
          {
            text: 'التصنيفات',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-members',
      name: 'add-members',
      component: () => import('@/views/admin/members/AddMember'),
      meta: {
        authRequired: true,
        role: 'admin',
        pageTitle: 'التصنيفات',
        breadcrumb: [
          {
            text: 'التصنيفات',
            active: true,
          },
        ],
      },
    },
    {
      path: '/members',
      name: 'members',
      component: () => import('@/views/admin/members/AllMembers'),
      meta: {
        authRequired: true,
        role: 'admin',
        pageTitle: 'التصنيفات',
        breadcrumb: [
          {
            text: 'التصنيفات',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-coaches',
      name: 'add-coaches',
      component: () => import('@/views/admin/coaches/AddCoach'),
      meta: {
        authRequired: true,
        role: 'admin',
        pageTitle: 'التصنيفات',
        breadcrumb: [
          {
            text: 'التصنيفات',
            active: true,
          },
        ],
      },
    },
    {
      path: '/coaches',
      name: 'coaches',
      component: () => import('@/views/admin/coaches/AllCoaches'),
      meta: {
        authRequired: true,
        role: 'admin',
        pageTitle: 'التصنيفات',
        breadcrumb: [
          {
            text: 'التصنيفات',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-rooms',
      name: 'add-rooms',
      component: () => import('@/views/admin/rooms/AddRoom'),
      meta: {
        authRequired: true,
        role: 'admin',
        pageTitle: 'التصنيفات',
        breadcrumb: [
          {
            text: 'التصنيفات',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rooms',
      name: 'rooms',
      component: () => import('@/views/admin/rooms/AllRooms'),
      meta: {
        authRequired: true,
        role: 'admin',
        pageTitle: 'التصنيفات',
        breadcrumb: [
          {
            text: 'التصنيفات',
            active: true,
          },
        ],
      },
    },
    {
      path: '/packages',
      name: 'packages',
      component: () => import('@/views/admin/memberShip/AllPackage.vue'),
      meta: {
        authRequired: true,
        role: 'admin',
        pageTitle: 'الباقات',
        breadcrumb: [
          {
            text: 'الباقات',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-packages',
      name: 'add-packages',
      component: () => import('@/views/admin/memberShip/AddPackage.vue'),
      meta: {
        authRequired: true,
        role: 'admin',
        pageTitle: 'إضافة تصنيف',
        breadcrumb: [
          {
            text: 'إضافة تصنيف',
            active: true,
          },
        ],
      },
    },
    {
      path: '/subscribes',
      name: 'subscribes',
      component: () => import('@/views/admin/subscribe/AllSubscribe'),
      meta: {
        authRequired: true,
        role: 'admin',
        pageTitle: 'الاشتراكات',
        breadcrumb: [
          {
            text: 'الاشتراكات',
            active: true,
          },
        ],
      },
    },
    {
      path: '/subscribes/active',
      name: 'subscribes-active',
      component: () => import('@/views/admin/subscribe/ActiveSubscribe'),
      meta: {
        authRequired: true,
        role: 'admin',
        pageTitle: 'الاشتراكات',
        breadcrumb: [
          {
            text: 'الاشتراكات',
            active: true,
          },
        ],
      },
    },
    {
      path: '/subscribes/expired',
      name: 'subscribes-expired',
      component: () => import('@/views/admin/subscribe/ExpiredSubscribe'),
      meta: {
        authRequired: true,
        role: 'admin',
        pageTitle: 'الاشتراكات',
        breadcrumb: [
          {
            text: 'الاشتراكات',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-subscribe',
      name: 'add-subscribe',
      component: () => import('@/views/admin/subscribe/AddSubscribe.vue'),
      meta: {
        authRequired: true,
        role: 'admin',
        pageTitle: 'إضافة اشتراك',
        breadcrumb: [
          {
            text: 'إضافة اشتراك',
            active: true,
          },
        ],
      },
    },
    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/views/admin/reports/AllReports.vue'),
      meta: {
        authRequired: true,
        role: 'admin',
        pageTitle: 'التقاريير',
        breadcrumb: [
          {
            text: 'التقاريير',
            active: true,
          },
        ],
      },
    },
    {
      path: '/setting/all-settings',
      name: 'setting.all',
      component: () => import('@/views/admin/setting/AllSettings'),
      meta: {
        authRequired: true,
        role: 'admin',
        pageTitle: 'الإعدادات',
        breadcrumb: [
          {
            text: 'الإعدادات',
            active: true,
          },
        ],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        authRequired: false,
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/admin/error/Error404.vue'),
      meta: {
        authRequired: false,
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.meta.authRequired) {
    if (!auth.isAuthenticated()) {
      // return next()
      return router.push({ path: 'login', query: { to: to.path } })
    }
    // if (to.meta.role !== auth.role()) {
    //   console.log(auth.role())
    //   return router.push({ path: '/' })
    // }
  }
  if (to.meta.login) {
    if (auth.isAuthenticated()) {
      // return next()
      return router.push({ path: '/rooms' })
    }
  }
  return next()
})
export default router
