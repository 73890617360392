<template>
  <div>
    <!-- Add Admin -->
    <b-card-group deck>
      <b-card
        header-tag="header"
        title="إضافة اشتراك جديد"
      >
        <validation-observer ref="adminRules">
          <b-form>
            <b-row>
              <!-- name -->
              <b-col md="6">
                <b-form-group
                  label="اسم العضو"
                  label-for="member"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="role"
                    rules="required"
                  >
                    <b-form-select
                      id="role"
                      v-model="member"
                      value-field="id"
                      text-field="name"
                      placeholder="اسم العضو"
                      :options="members"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="الاشتركات"
                  label-for="role"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="role"
                    rules="required"
                  >
                    <b-form-select
                      id="role"
                      v-model="memberShip"
                      value-field="id"
                      text-field="name"
                      placeholder="الاشتركات"
                      :options="memberShips"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- create button -->
              <b-col cols="12">
                <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  إضافة
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@/@core/utils/validations/validations'
import {
  BCard,
  BCardGroup,
  BButton,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormSelect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const dictionary = {
  ar: {
    names: {
      name: 'الاسم',
      email: 'البريد الالكتروني',
      password: 'كلمة المرور',
      phone: 'رقم الهاتف',
      role: 'النوع',
    },
  },
}
localize('ar', dictionary.ar)
export default {
  name: 'AddCategory',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardGroup,
    BButton,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormSelect,
  },
  data() {
    return {
      name: null,
      email: '',
      password: '',
      phone: '',
      member: '',
      memberShip: '',
      members: {},
      memberShips: {},
      required,
    }
  },
  created() {
    this.$store.dispatch('member/fetch').then(data => {
      this.members = data.data.data
    })
    this.$store.dispatch('memberShip/fetch').then(data => {
      this.memberShips = data.data.data
    })
  },
  methods: {
    validationForm() {
      const formData = new FormData()
      formData.append('member_id', this.member)
      formData.append('member_ship_id', this.memberShip)
      console.log(formData)
      this.$refs.adminRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('subscribe/store', formData).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'تم إضافة الاشترك بنجاح',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
        }
      })
    },
    fileCategory(event) {
      // eslint-disable-next-line prefer-destructuring
      this.category_file = event.target.files[0]
    },
  },
}
</script>

<style scoped></style>
