import axios from '@axios'

export default {
  namespaced: true,
  state: {
    coaches: [],
  },
  getters: {},
  mutations: {
    SET_RECORD(state, categories) {
      state.coaches = categories
    },
    UPDATE_RECORD(state, data) {
      const catIndex = state.coaches.findIndex(cat => cat.id === data.id)
      Object.assign(state.coaches[catIndex], data)
    },
    REMOVE_RECORD(state, itemId) {
      const caseIndex = state.coaches.findIndex(d => d.id === itemId)
      state.coaches.splice(caseIndex, 1)
    },
  },
  actions: {
    store(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.post('admin/coaches/create', data)
          .then(res => {
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },
    fetch({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('admin/coaches')
          .then(response => {
            commit('SET_RECORD', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    edit(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.post('admin/coaches/update', data)
          .then(res => {
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },
    delete({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.post('admin/coaches/delete', { coach_id: id })
          .then(res => {
            commit('REMOVE_RECORD', id)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },
  },
}
