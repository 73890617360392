<template>
  <div>
    <b-card
      ref="cardAction"
      title="كافة الكلاسات"
    >
      <b-row>
        <b-col cols="4">
          <b-form-group
            label="التاريخ"
            label-for="birthday"
          >
            <b-form-input
              id="birthday"
              v-model="searchDate"
              type="date"
              placeholder="تاريخ"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-button
            class="mt-2"
            size="md"
            variant="primary"
            @click="filter()"
          > فحص </b-button>

        </b-col>
      </b-row>
    </b-card>

    <div>
      <b-row>
        <div
          v-for="room in getRooms"
          :key="room.id"
          class="col-lg-3 col-md-4 col-sm-6 col-12"
        >
          <b-card style="min-height: 200px">
            <template #header>
              <h4 class="mb-2 h1 text-center mx-auto">
                {{ room.name }}</h4>
              <p class="text-muted">
                {{ room.description }}
              </p>
            </template>
            <b-card-text>

              <div
                dir="rtl"
                style="text-align: right"
              >
                <p class="h4 mb-2">
                  <span>
                    وقت البدء :
                  </span>
                  <span>
                    {{ room.started_at_time | shortTime }}
                  </span>
                </p>
                <p class="h4 mb-2">
                  <span>
                    الكوتش :
                  </span>
                  <span>
                    {{ room.coach ? room.coach.name : '' }}
                  </span>
                </p>
              </div>

            </b-card-text>
            <b-button
              href="#"
              variant="primary"
              size="md"
              @click="confirmDelete(room.id)"
            >
              حجز
            </b-button>
          </b-card>
        </div>
      </b-row>
    </div>
    <edit-member :obj="Obj" />
  </div>
</template>

<script>
import EditMember from '@/views/admin/members/EditMember.vue'
import {
  BRow, BCol, BButton, BCard, BFormInput, BFormGroup, BCardText,
} from 'bootstrap-vue'
import { faEye, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faEye, faTrash, faPlus)

export default {
  name: 'AllMembers',
  components: {
    BCard,
    BRow,
    BCol,
    // BTable,
    BButton,
    // BPagination,
    BFormInput,
    BFormGroup,
    BCardText,
    // BCardGroup,
    EditMember,
  },
  data() {
    return {
      searchDate: new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
      Obj: {},
      url: process.env.URL,
      perPage: 10,
      currentPage: 1,
      add: true,
      busy: true,
      file: null,
      fields: [
        { key: 'id', label: 'م' },
        { key: 'name', label: 'الاسم' },
        { key: 'description', label: 'الوصف' },
        { key: 'started_at_time', label: 'الوقت' },
        { key: 'coach', label: 'المدرب' },
        // { key: 'started_at', label: 'تاريخ البدء' },
        // { key: 'ended_at', label: 'تاريخ الانتهاء' },
        // { key: 'days', label: 'الايام' },
        // { key: 'status', label: 'الحالة' },
        { key: 'actions', label: 'العمليات' },
      ],
      items: [],
    }
  },
  computed: {
    rows() {
      // return this.getCategories.length
      return this.items.length
    },
    getRooms() {
      /* eslint-disable no-param-reassign */
      return this.$store.state.room.rooms
    },
  },
  created() {
    this.$store.dispatch('room/fetch').then(res => {
      this.items = res.data.data
    })
  },
  methods: {
    filter() {
      this.$store.dispatch('room/filter', this.searchDate).then(res => {
        this.items = res.data.data
      })
    },
    edit(data) {
      this.Obj = data
      this.$bvModal.show('EditMember')
    },
    subCat(id) {
      this.$router.push(`/all-sub-category/${id}`)
    },
    allAdds(id) {
      this.$router.push(`/all-ads-category/${id}`)
    },
    confirmDelete(id) {
      this.$swal({
        title: 'حجز الكلاس',
        text: 'هل تريد حجز الكلاس',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'تاكيد الحجز !',
        cancelButtonText: 'الغاء',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const data = {
            id,
            date: this.searchDate,
          }
          this.$store.dispatch('room/delete', data).then(() => {
            this.$swal({
              icon: 'success',
              title: 'عملية الحجز',
              text: 'تم حجز الكلاس بنجاح',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
